import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://marathi-news.in/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>
            Privacy Policy | Marathi News Live | Zee24 Taas, ABP Majha, TV9
          </title>
          <meta
            name="description"
            content="Read the privacy policy for www.marathi-news.in. Learn about data usage, third-party links, and cookies. Watch Zee24 Taas, ABP Majha, TV9 Marathi, Saam TV live."
          />
          <meta
            name="keywords"
            content="Privacy policy, Marathi news live, Zee24 Taas live, ABP Majha live, TV9 Marathi live, Saam TV live, News18 Lokmat live, Marathi news streaming privacy, YouTube Marathi news"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to www.marathi-news.in. Your privacy is important to us, and
            this privacy policy outlines the types of personal information we
            collect and how it is used and protected.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information Collection and Use</h2>
              <p>
                We do not collect personal information such as your name,
                address, or contact details unless you voluntarily provide it
                via email or any direct communication method. We may collect
                anonymous data related to your usage of our website, such as
                page views, device information, and interaction patterns, to
                improve our services.
              </p>
            </li>
            <li>
              <h2>Third-Party Links</h2>
              <p>
                Our website, www.marathi-news.in, provides{" "}
                <h3>live streaming of various Marathi news channels</h3> via
                embedded YouTube videos. All video content displayed on this
                site belongs to the respective owners, including:
              </p>
              <ul className="dash-list">
                <li>
                  Zee24 Taas – Watch live at [Zee24 Taas Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=NXdV9GqtXHc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=NXdV9GqtXHc
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  ABP Majha – Watch live at [ABP Majha Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=m0MD6Ukm0cQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=m0MD6Ukm0cQ
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  TV9 Marathi – Watch live at [TV9 Marathi Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=PXRUc5O4J84"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=PXRUc5O4J84
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Saam TV – Watch live at [Saam TV Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=13kXfFlfUdQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=13kXfFlfUdQ
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  News18 Lokmat – Watch live at [News18 Lokmat Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=UQ64XobiT0Y"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=UQ64XobiT0Y
                    </a>
                  </h3>
                  )
                </li>
              </ul>
            </li>
            <p>
              All video content belongs to the respective owners, and
              www.marathi-news.in does not claim ownership over any of the
              videos. We simply provide easy access to these live YouTube
              streams for convenience.
            </p>

            <li>
              <h2>Cookies</h2>
              <p>
                We may use cookies to improve your browsing experience. These
                cookies help us understand how you use the website and allow us
                to provide more relevant content. You can disable cookies in
                your browser settings if desired.
              </p>
            </li>
            <li>
              <h3>External Links</h3>
              <p>
                This website contains external links to YouTube videos. Clicking
                these links will direct you to YouTube, and their privacy policy
                will apply when you engage with their content. We recommend
                reviewing YouTube’s privacy policy for further details.
              </p>
            </li>
            <li>
              <h2>Data Security</h2>
              <p>
                We do not store sensitive personal data. Our website is
                protected using industry-standard security measures to ensure
                your browsing experience is safe and secure.
              </p>
            </li>
            <li>
              <h2>Contact Information</h2>
              <p>
                If you have any questions or concerns regarding this privacy
                policy or how your information is handled, feel free to contact
                us at contact@marathi-news.in.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
