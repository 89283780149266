import React, { createContext, useContext, useState } from "react";

interface DropdownContextType {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  getIframeSrc: () => string;
}

export const DropdownContext = createContext<DropdownContextType | undefined>(
  undefined
);

export const DropdownProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("Marathi"); // Default value
  const getIframeSrc = () => {
    return "your_iframe_src";
  };

  return (
    <DropdownContext.Provider
      value={{ selectedOption, setSelectedOption, getIframeSrc }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (context === undefined) {
    throw new Error("useDropdown must be used within a DropdownProvider");
  }
  return context;
};
