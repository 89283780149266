import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "zee24taas", For eg: "newlogoname",
  const [tabs] = useState([
    "abpmaza",
    "news18lokmat",
    "saamtv",
    "tv9marathi",
    "zee24taas",
  ]);

  const [selectedMarathiTab, setSelectedMarathiTab] = useState<string>(
    () => localStorage.getItem("selectedMarathiTab") || "zee24taas"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedMarathiTab(channelKey);
    localStorage.setItem("selectedMarathiTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedMarathiTab");
    if (savedTab) {
      setSelectedMarathiTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["abpmaza", "news18lokmat"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedMarathiTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedMarathiTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const abpmaza = `/assets/channel-logos/abp-maza.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const news18lokmat = `/assets/channel-logos/news18-lokmat.png?v=${cacheBuster}`;
  const saamtv = `/assets/channel-logos/saam-tv.jpeg?v=${cacheBuster}`;
  const tv9marathi = `/assets/channel-logos/tv9-marathi.jpeg?v=${cacheBuster}`;
  const zee24taas = `/assets/channel-logos/zee24-taas.jpeg?v=${cacheBuster}`;

  const imagesToPreload = [
    abpmaza,
    news18lokmat,
    saamtv,
    tv9marathi,
    zee24taas,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Marathi News Live | Zee24 Taas, ABP Majha, TV9, Saam TV, Lokmat
          </title>
          <meta
            name="description"
            content="Watch live streaming of Zee24 Taas, ABP Majha, TV9 Marathi, Saam TV, News18 Lokmat on Marathi-News.in. Stay updated with the latest Marathi news from trusted channels."
          />
          <meta
            name="keywords"
            content="Marathi news live, Zee24 Taas live, ABP Majha live, TV9 Marathi live, Saam TV live, News18 Lokmat live, Marathi breaking news, live Marathi news streaming, Marathi TV news, Marathi news channels"
          />
        </Helmet>
        <Header />

        {selectedOption === "Marathi" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line no. 109 to 128) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("zee24taas") && (
                  <div
                    className={`tab-item ${
                      selectedMarathiTab === "zee24taas" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("zee24taas")}
                  >
                    {/**** When you add new logo change the src={zee24taas} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zee24taas}
                        alt="Zee24 Taas Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={zee24taas} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div(from line no. 109 to 128) and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("abpmaza") && (
                  <div
                    className={`tab-item ${
                      selectedMarathiTab === "abpmaza" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("abpmaza")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abpmaza}
                        alt="ABP Majha Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tv9marathi") && (
                  <div
                    className={`tab-item ${
                      selectedMarathiTab === "tv9marathi" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("tv9marathi")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9marathi}
                        alt="TV9 Marathi Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("saamtv") && (
                  <div
                    className={`tab-item ${
                      selectedMarathiTab === "saamtv" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("saamtv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={saamtv}
                        alt="Saam TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18lokmat") && (
                  <div
                    className={`tab-item ${
                      selectedMarathiTab === "news18lokmat" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("news18lokmat")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18lokmat}
                        alt="News18 Lokmat Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedMarathiTab === "zee24taas" &&
                visibleTabs.includes("zee24taas") && (
                  <>
                    <h1>Zee24 Taas Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/NXdV9GqtXHc?si=43knj_gWx2O1AXYV&autoplay=1&v=${cacheBuster}`}
                      title="Zee24 Taas Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedMarathiTab === "abpmaza" &&
                visibleTabs.includes("abpmaza") && (
                  <>
                    <h1>ABP Majha Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/m0MD6Ukm0cQ?si=t3ViovGCuGZyHdRo&autoplay=1&v=${cacheBuster}`}
                      title="ABP Majha Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMarathiTab === "tv9marathi" &&
                visibleTabs.includes("tv9marathi") && (
                  <>
                    <h1>TV9 Marathi Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/PXRUc5O4J84?si=Cq4MnxTSlnMqqrON&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Marathi Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMarathiTab === "saamtv" &&
                visibleTabs.includes("saamtv") && (
                  <>
                    <h1>Saam TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/13kXfFlfUdQ?si=6NIhFnr7TDhwyQqH&autoplay=1&v=${cacheBuster}`}
                      title="Saam TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMarathiTab === "news18lokmat" &&
                visibleTabs.includes("news18lokmat") && (
                  <>
                    <h1>News18 Lokmat Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/U_v9Jnbdhdc?si=g083Mrn3YjOxogr2&autoplay=1&v=${cacheBuster}`}
                      title="News18 Lokmat Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>
      <div style={{ display: "none" }}>
        <p>
          Welcome to Marathi News Live – Your Ultimate Destination for 24/7
          Marathi News{" "}
        </p>
        <p>
          At Marathi News Live, we provide seamless access to the best Marathi
          news channels, offering real-time updates on politics, business,
          sports, entertainment, and more. Stay informed with live streaming of
          trusted channels such as Zee24 Taas Live, ABP Majha Live, TV9 Marathi
          Live, Saam TV Live, and News18 Lokmat Live. No matter where you are,
          you can stay connected to Maharashtra and beyond with reliable and
          timely news updates.
        </p>

        <p>
          All Your Favorite Marathi News Channels in One Place Our platform
          simplifies the way you consume news by offering a single space to
          watch the top Marathi channels live. Zee24 Taas Live and ABP Majha
          Live provide comprehensive coverage of state, national, and global
          events. Whether it’s breaking news or in-depth analysis, you’ll find
          all the updates you need on Marathi News Live.
        </p>

        <p>
          Real-Time Updates for Every Audience Whether you’re looking for
          updates on politics, cultural events, or the latest sports scores,
          Marathi News Live has you covered. Channels like TV9 Marathi Live and
          Saam TV Live deliver fast, accurate, and engaging news directly to
          your fingertips. With real-time streaming, you’ll never miss an
          important development.
        </p>

        <p>
          Insights, Perspectives, and Local Stories Stay closer to home with
          localized coverage from trusted sources. News18 Lokmat Live and Saam
          TV Live ensure you get detailed reports on the events and issues that
          matter most to Maharashtra. From grassroots stories to insightful
          debates, Marathi News Live brings you a well-rounded view of the world
          through a Marathi lens.
        </p>

        <p>
          Why Choose Marathi News Live? Marathi News Live is your one-stop
          destination for trustworthy and uninterrupted news. With easy
          navigation and direct links to live YouTube streams of the most
          popular Marathi channels, we make it easier than ever to stay
          informed. Bookmark our website today and experience the convenience of
          having all your favorite Marathi news channels at your fingertips.
          Stay connected, stay informed, and explore the power of Marathi
          journalism with us!
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
